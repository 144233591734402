<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Create</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" outlined small @click="goBack">
          <v-icon left dark>mdi-skip-backward</v-icon>Back
        </v-btn>
      </v-toolbar>
      <!-- editor -->
      <editor :initial="dataItem" @data="save"></editor>
      <!-- end -->
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    itemsData: [],
    editMode: false,
  }),
  methods: {
    save(data) {
      const url = "/subjects_and_actions";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          this.loader = true;

          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show("Created successfully", "green");
            this.goBack();
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
